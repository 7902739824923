;(function () {
  const fileFieldApp = function (hintElementId, maxHintLength) {
    return {
      hintElement: document.getElementById(hintElementId),
      onFileFieldChange: function (e, $dispatch) {
        const filename = e.target.files[0]?.name

        this.hintElement.innerHTML =
          filename.length <= maxHintLength
            ? filename
            : filename.slice(0, maxHintLength) +
              '...' +
              filename.slice(filename.length - 10, filename.length)

        $dispatch('file-selected', filename)
      },
    }
  }

  if (typeof window !== 'undefined') {
    window.fileFieldApp = fileFieldApp
  }

  return fileFieldApp
})()
