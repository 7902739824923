import Sortable from "sortablejs";

(function () {
  const draggableApp = function () {
    return {
      init: function(element, callback) {
        this.sortable = Sortable.create(element, {
          onEnd: callback,
          animation: 200,
          filter: '.ignore-drag',
        });
      },
    }
  }

  if (typeof window !== "undefined") {
    window.draggableApp = draggableApp;
  }

  return draggableApp;
})();
