import { camelizeKeys } from './utils'
import _ from 'lodash'

(function () {
  function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' &&
          !(data instanceof Date) &&
          !(data instanceof File) &&
          !(data instanceof Blob)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
      })
    } else {
      const value = data == null ? '' : data

      formData.append(parentKey, value)
    }
  }

  const appraisalCheckingApp = function (
    targetData,
    mode,
    orderFileId = null,
    checks = [],
    submissionChecks = [],
    commonResponses = []
  ) {
    let $dispatch

    return {
      init: function (dispatch) {
        $dispatch = dispatch
      },
      reviewStrategy: '',
      loading: false,
      succeed: false,
      orderFileId: orderFileId,
      checks: checks,
      submissionChecks: submissionChecks,
      commonResponses: commonResponses,
      checksComments: '',
      errors: [],
      appMode: ['review', 'submit', 'report'].filter((m) => m === mode)[0] || 'review',
      selectedFile: null,
      selectedFileName: function () { return this.selectedFile?.name || '' },
      failedChecks: function () { return this.checks.filter(check => !check.passes) },
      isEmpty: function () { return !this.selectedFile },
      reject: function (check) {
        check.passes = false
        check.rejected = !check.rejected
        this.publishChange()
      },
      accept: function (check) {
        check.passes = !check.passes
        check.rejected = false
        this.publishChange()
      },
      toggleCheck: function (check) {
        check.passes = !check.passes
        check.skipped = check.passes
        this.publishChange()
      },
      publishChange: function () {
        this.publishReadyCount({ succeed: !this.failedChecks().length })
      },
      selectFile: function (file, tags) {
        const __this = this

        this.selectedFile = file
        this.checks = []

        if (file.type !== 'text/xml') {
          __this.publishReadyCount({ succeed: true })

          return
        }

        this.submitAndCheck(file, tags)
          .then(() => {
            __this.publishReadyCount({ succeed: !__this.failedChecks().length })
          })
      },
      publishReadyCount: function ({ succeed }) {
        let readyCountAlpha = 0

        if (this.succeed && !succeed) {
          readyCountAlpha--
        } else if (!this.succeed && succeed) {
          readyCountAlpha++
        }

        this.succeed = succeed

        $dispatch('file-changed', { readyCount: readyCountAlpha })
      },
      submitAndCheck: function(appraisal, tags) {
        const __this = this
        const __$dispatch = $dispatch

        const data = {
          target_data: targetData,
          appraisal: appraisal,
          tags: tags
        }
        const formData = new FormData()
        buildFormData(formData, data)

        this.loading = true

        return fetch('/appraisal_checking/checks', { method: 'POST', body: formData })
          .then(response => response.json())
          .then(checks => {
            checks = camelizeKeys(checks)

            __this.loading = false

            if (!checks.error) {
              __this.checks = checks
            } else {
              __this.errors = [checks.error, ...__this.errors]
            }

            __$dispatch('request-finished', {})
          })
      },
      showErrors: function () {
        return this.errors.length > 0
      },
      submitReview: function (tags = [], report = false) {
        const body = JSON.stringify({
          report,
          reviews: { tags, checks: this.checks },
          review_strategy: this.reviewStrategy,
          checks_comments: this.checksComments
        })

        return new FetchRequest('POST',
          `/order_files/${orderFileId}/reviews`,
          { body, responseKind: 'turbo-stream', contentType: 'application/json' }).perform()
      },
      getChecksComments: function () {
        this.checksComments = this.checks.map(check => {
          return check.comment
        }).filter((a) => a).join('\n')
      },
      useCommonResponse: function (check, responseId) {
        if (!responseId) {
          check.comment = ''
        } else {
          const response = this.commonResponses.find((r) => r.id === responseId)

          check.comment = response.response
        }
      },
    }
  }

  if (typeof window !== "undefined") {
    window.appraisalCheckingApp = appraisalCheckingApp
  }

  return appraisalCheckingApp
})()
