(function () {
  const newNoteApp = function (customStatuses = {}) {
    function loadCustomNote(customStatusId, isClient) {
      const key = isClient ? 'client_note' : 'default_note'
      const fallback = customStatuses[customStatusId]?.default_note
      const customNote = customStatuses[customStatusId]?.[key] || fallback || ''

      if (!customNote.length > 0) return

      document.getElementById('note_content').value = customNote
    }

    return {
      backToStatus: false,
      showSubject: false,
      isClient: false,
      customStatusId: null,
      orderCustomStatusChange: function(event) {
        this.customStatusId = event.target.value

        loadCustomNote(this.customStatusId, this.isClient)
      },
      noteTypeChange: function(event) {
        this.showSubject = ['client', 'all_roles'].includes(event.target.value)
        this.isClient = event.target.value == 'client'

        loadCustomNote(this.customStatusId, this.isClient)
      }
    }
  }

  if (typeof window !== "undefined") {
    window.newNoteApp = newNoteApp
  }

  return newNoteApp;
})()
