;(function () {
  const clientAutoAssignmentApp = function (company, clientAutoAssign) {
    return {
      autoAssignEnabled: clientAutoAssign.enabled,
      preferredVendorsEnabled: clientAutoAssign.preferred_vendors,
      payingValidation: {},
      opened: false,
      showWarnings: false,
      fetchPayingValidations: function () {
        fetch(
          `/paying/companies/${company.id}/clients/${clientAutoAssign.client_id}/fee_table`
        )
          .then((response) => response.json())
          .then((response) => (this.payingValidation = response))
          .then((_) => (this.showWarnings = true))
      },
      orderingErrors: function () {
        let errors = []

        if (!this.autoAssignEnabled) return errors

        if (!this.preferredVendorsEnabled) {
          errors.push('preferred_vendors')
        }

        return errors
      },
      payingErrors: function () {
        let errors = []

        if (!this.autoAssignEnabled) return errors

        if (!this.payingValidation.product_fee_table_id) {
          errors.push('missing_product_fee')
        }
        if (
          !this.payingValidation.vendor_fees?.filter((number) =>
            Boolean(parseFloat(number))
          ).length
        ) {
          errors.push('missing_vendor_fees')
        }

        return errors
      },
    }
  }

  if (typeof window !== 'undefined') {
    window.clientAutoAssignmentApp = clientAutoAssignmentApp
  }

  return clientAutoAssignmentApp
})()
